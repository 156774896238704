// 引入文件
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Login from "../components/Login";
import Register from "../components/Register";

// new 路由对象
export  default new VueRouter({
    mode:'history', // history模式 url不含#

    // 路由列表
    routes: [
        // home
        {
            path: '/',
            name: 'login',
            component: Login,
            meta: {
                index: 0,
            }
        },
        // login
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                index: 0,
            }
        },
        // register
        {
            path: '/register',
            name:'register',
            component:Register,
            meta:{
                index:0,
            }
        },
        //text
        {
            path: '/text',
            name: 'text',
            component: Register,
            meta: {
                index: 1,
            }
        }
    ]
})